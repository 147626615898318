<template>
  <div class="address-mana">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"
        ><i class="el-icon-s-home"></i
      ></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">会员中心</el-breadcrumb-item>
      <el-breadcrumb-item>地址管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <h3>地址管理</h3>
      <div
        style="display: flex; margin-bottom: 20px; justify-content: flex-end"
      >
        <el-button @click="newAddress">新地址</el-button>
      </div>

      <el-table
        border
        :header-cell-style="{ background: '#ebecf0', color: '#000' }"
        :data="addressList"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          prop="time"
          label="创建时间"
          width="180"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="contract_name"
          label="姓名"
          width="180"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="contract_phone"
          label="电话"
          width="180"
        >
        </el-table-column>
        <el-table-column align="center" label="城市" width="180">
          <template slot-scope="scope">
            {{ scope.row.province }} {{ scope.row.city }} {{ scope.row.county }}
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          align="center"
          prop="address"
          label="详细地址"
        >
        </el-table-column>
        <el-table-column width="180" align="center" label="管理">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-dialog title="修改地址" :visible.sync="dialogFormVisible">
        <el-form
          :model="form"
          style="
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          "
        >
          <el-form-item
            style="width: 50%"
            label="姓名"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="form.contract_name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="width: 50%"
            label="电话"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="form.contract_phone"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="width: 50%"
            label="城市"
            :label-width="formLabelWidth"
          >
            <el-cascader
              @change="cascaderChange"
              placeholder="选择地区"
              :options="options"
              filterable
              v-model="form.region"
            />
          </el-form-item>
          <el-form-item
            style="width: 50%"
            label="详细地址"
            :label-width="formLabelWidth"
          >
            <el-input v-model="form.address" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </div>
      </el-dialog>

      <!-- <div class="address-list">
        <img src="../../assets/logo.png" alt="" />
        <p>暂无地址欧！</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import { http } from "../../../request/http";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  name: "addressMana",
  data() {
    return {
      options: regionData,
      addressList: [],
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "120px",
      dialogFormVisible: false,
    };
  },
  mounted() {
    this.getAddressList();
  },
  methods: {
    newAddress() {
      this.$router.push("/newAddress");
    },
    // 获取收获地址
    getAddressList() {
      http("user.php", {
        fun: "myAddress",
      }).then((res) => {
        if (res.code == 0) {
          console.log(res);
          let list = res.data.sort((a, b) => {
            return new Date(b.time) - new Date(a.time);
          });
          this.addressList = list;
        }
      });
    },
    // 修改
    handleEdit(index, row) {
      this.dialogFormVisible = true;
      let province = TextToCode[row.province].code;
      let city = TextToCode[row.province][row.city].code;
      let county = TextToCode[row.province][row.city][row.county].code;
      this.form = row;
      this.form.region = [province, city, county];
    },
    // 删除
    handleDelete(index, row) {
      this.$confirm("确认要删除吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          http("user.php", {
            fun: "delUserAddress",
            id: row.id,
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getAddressList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    cascaderChange(e) {
      console.log(e);
      console.log(this.form.region);
    },
    submit() {
      let {
        id,
        address,
        contract_name,
        contract_phone,
        province,
        city,
        region,
        county,
      } = this.form;
      province = CodeToText[region[0]];
      city = CodeToText[region[1]];
      county = CodeToText[region[2]];
      http("user.php", {
        fun: "editUserAddress",
        id,
        address,
        contract_name,
        contract_phone,
        province,
        city,
        county,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.dialogFormVisible = false;
          this.getAddressList();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/scss/index";
:deep( .el-table--scrollable-x .el-table__body-wrapper) {
  overflow-x: hidden;
}
.address-mana {
  @include body;
  .address-list {
    @include flexCenter($direction: column, $justify: unset);
  }
}
</style>
